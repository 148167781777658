import React, { Suspense, useEffect, useState } from "react";
import {
  Wrapper,
  Title,
  MainSubtitle,
  Grid,
  Picture,
  TimeCard,
  BookNow
} from "./style";
import axios from "axios";
import { AnimatedWrap } from "../../../components/commons/AnimatedComponents";
import { setHeaders } from "../../../utils/setHeaders";
import { createBrowserHistory } from "history";

/* const Paragraph = lazy(() => import("../../../components/commons/Paragraph"));
const Cta = lazy(() => import("../../../components/commons/cta")); */

import Paragraph from "../../../components/commons/Paragraph"
import Cta from "../../../components/commons/cta"

export default () => {
  const [apiData, setApiData] = useState(null);

  useEffect(() => {
    fetchData("https://morritts.com/wordpress/wp-json/wp/v2/pages/614");
  }, []);
  const history = createBrowserHistory();

  async function fetchData(url) {
    try {
      const response = await axios.get(url);
      setApiData(response.data.acf);
      setHeaders(response.data, history.location.pathname);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Suspense fallback={null}>
      {apiData ? (
        <AnimatedWrap>
          <Wrapper>
            <Title>{apiData.title}</Title>
            <Grid>
              <div>
                <Picture bg={apiData.left_image} alt={"spa"} />
              </div>
              <div>
                <MainSubtitle
                  mobileLineHeight={"24px"}
                  mobileSize={"16px"}
                  title={apiData.subtitle}
                ></MainSubtitle>
                <Paragraph text={apiData.text} />
                <TimeCard>
                  <span>{apiData.open_from}</span>
                  <h6>{apiData.hours}</h6>
                </TimeCard>
                <BookNow href={apiData.book_now_url} target="_blank">
                  <Cta>BOOK NOW</Cta>
                </BookNow>
              </div>
            </Grid>
          </Wrapper>
        </AnimatedWrap>
      ) : (
        <div></div>
      )}
    </Suspense>
  );
};
