import React, { Suspense, useEffect, useState } from "react";

// GOOGLE MAPS
import GoogleMapReact from "google-map-react";

// STYLES
import {
  Wrapper,
  Maps,
  RightSection,
  Title,
  Text,
  ContactEmail,
  AdressWrap,
  Reservations,
  Tel
} from "./style";

// COMPONENTS
import ContactForm from "../contactForm/contactForm";
import BgPicture from "../../../assets/contact-bg.jpg";
import axios from "axios";
import { AnimatedWrap } from "../../../components/commons/AnimatedComponents";
import { convertPhone } from "../../../utils/constants";

/* const Breadcrums = lazy(() =>
  import("../../../components/commons/breadcrumbs")
);

const Marker = lazy(() => import("../mapMarker")); */

import Breadcrums from "../../../components/commons/breadcrumbs"
import Marker from "../mapMarker";

export default () => {
  const [info, setInfo] = useState(null);
  const [frontDeskTel, setFrontDeskTel] = useState("");
  const [frontDeskTel2, setFrontDeskTel2] = useState("");
  const [reservationTel, setReservationTel] = useState("");

  // MAPS OPTIONS
  const defaultMapOptions = {
    fullscreenControl: false
  };
  const mapProperties = {
    center: {
      lat: 19.3453866,
      lng: -81.0911063
    },
    zoom: 16
  };

  useEffect(() => {
    if (info && frontDeskTel === "") {
      setFrontDeskTel(convertPhone(info.front_desk_tel));
    }
    if (info && frontDeskTel === "") {
      setFrontDeskTel2(convertPhone(info.front_desk_tel_2));
    }
    if (info && reservationTel === "") {
      setReservationTel(convertPhone(info.reservations_tel));
    }
  }, [info, frontDeskTel, reservationTel]);

  useEffect(() => {
    getInfo("https://morritts.com/wordpress/wp-json/wp/v2/general_info/");
  }, []);

  async function getInfo(url) {
    try {
      const response = await axios.get(url);

      setInfo(response.data[0].acf);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Suspense fallback={<div></div>}>
      {info ? (
        <AnimatedWrap>
          <Wrapper bg={BgPicture}>
            <Maps>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: "AIzaSyD0dS9KUN231a2Lj1FdN_0sjb2Px4Elr4I"
                }}
                defaultZoom={mapProperties.zoom}
                defaultCenter={mapProperties.center}
                options={defaultMapOptions}
              >
                <Marker
                  lat={19.3453866}
                  lng={-81.0911063}
                  text="Here"
                  name="My Marker"
                  color="#ea4435"
                />
              </GoogleMapReact>
            </Maps>
            <RightSection>
              <Breadcrums />
              <Title>Contact</Title>
              <div>
                <ContactForm
                  selectOptions={info.departments_contact_options}
                ></ContactForm>

                <Reservations>
                  <h5>Front Desk: </h5>
                  <Tel
                    href={`tel:${
                      info.front_desk_tel.substring(0, 1) === "+" ? "+" : null
                    }${frontDeskTel}`}
                  >
                    <h3>{info.front_desk_tel} or</h3>
                  </Tel>

                  <Tel
                    href={`tel:${
                      info.front_desk_tel_2.substring(0, 1) === "+" ? "+" : null
                    }${frontDeskTel2}`}
                  >
                    <h3>{info.front_desk_tel_2}</h3>
                  </Tel>
                </Reservations>
                <Reservations>
                  <h5>Reservations: </h5>
                  <Tel
                    href={`tel:${
                      info.reservations_tel.substring(0, 1) === "+" ? "+" : null
                    }${reservationTel}`}
                  >
                    <h3>{info.reservations_tel}</h3>
                  </Tel>
                </Reservations>
                <ContactEmail href={`mailto:${info.mail}`}>
                  {info.mail}
                </ContactEmail>
              </div>
              <AdressWrap>
                <Text
                  dangerouslySetInnerHTML={{ __html: info.address_contact }}
                />
              </AdressWrap>
            </RightSection>
          </Wrapper>
        </AnimatedWrap>
      ) : (
        <div></div>
      )}
    </Suspense>
  );
};
