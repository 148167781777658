import React, { Suspense, lazy, useEffect, useState } from "react";

import { Wrapper } from "./style";
import axios from "axios";
import { AnimatedWrap } from "../../components/commons/AnimatedComponents";
import {setHeaders} from "../../utils/setHeaders"
import { createBrowserHistory } from "history";

/* const HeroViews = lazy(() => import("../../components/commons/heroViews"));
const SpecsColumnsx3 = lazy(() =>
  import("../../components/commons/specsColumnsx3")
);
const FeaturesStay = lazy(() => import("./featuresStay")); */

import HeroViews from "../../components/commons/heroViews"
import SpecsColumnsx3 from "../../components/commons/specsColumnsx3"
import FeaturesStay from "./featuresStay"

export default () => {
  const [data, setData] = useState(null);
  const [features, setFeatures] = useState([]);
  const [hotelsInfo, setHotelsInfo] = useState(null);
  const finalHotels = [];
  const history = createBrowserHistory();

  useEffect(() => {
    fetchData("https://morritts.com/wordpress/wp-json/wp/v2/pages/212");
    fetchHotels(
      "https://morritts.com/wordpress/wp-json/wp/v2/pages?per_page=100"
    );
  }, []);

  async function fetchData(url) {
    try {
      const response = await axios.get(url);
      setData(response.data);
      setHeaders(response.data, history.location.pathname);
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchFeatures(url) {
    try {
      const response = await axios.get(url);
      setFeatures(features => [...features, response.data]);
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchHotels(url) {
    try {
      const response = await axios.get(url);
      setHotelsInfo(response.data.slice(0, -6).reverse());
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (data && features < data.acf.stay_features.length) {
      data.acf.stay_features.forEach(item => {
        fetchFeatures(
          "https://morritts.com/wordpress/wp-json/wp/v2/features/" + item.ID
        );
      });
    }
  }, [data, features]);

  if (hotelsInfo) {
    hotelsInfo.map(singleHotel => {
      if (singleHotel.acf.hotel_title) {
        console.log(singleHotel.id, singleHotel.title, singleHotel);
        return finalHotels.push(singleHotel);
      }
      /*if ([241, 280, 310].includes(singleHotel.id)) {
        return finalHotels.push(singleHotel);
      }*/
      /*if (singleHotel.id === 241) {
        return finalHotels.push(singleHotel);
      }
      if (singleHotel.id === 280) {
        return finalHotels.push(singleHotel);
      }
      if (singleHotel.id === 310) {
        return finalHotels.push(singleHotel);
      }*/
      return null;
    });
  }

  return (
    <Suspense fallback={<div></div>}>
      {data ? (
        <Wrapper>
          <AnimatedWrap>
            <HeroViews bg={data.acf.stay_hero_image} title={"Stay "} />
            <FeaturesStay data={data} features={features} />
            <SpecsColumnsx3 hotelsInfo={finalHotels} />
          </AnimatedWrap>
        </Wrapper>
      ) : (
        <div></div>
      )}
    </Suspense>
  );
};
