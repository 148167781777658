import React, { Suspense, useContext, useEffect, useState } from "react";
import { ThemeContext } from "styled-components";
import { useMediaQuery } from "react-responsive";

// STYLES
import {
  Wrapper,
  SliderWrap,
  MobileWrap,
  TitleMobile,
  SubtitleMobile,
  RoundedMobilePic,
  MobileFullPicture
} from "./style";

import axios from "axios";
import { setHeaders } from "../../utils/setHeaders";
import { AnimatedWrap } from "../../components/commons/AnimatedComponents";
import { createBrowserHistory } from "history";

// COMPONENTS
/* const HeroViews = lazy(() => import("../../components/commons/heroViews"));
const CaymanSection = lazy(() => import("./caymanSection"));
const MainSlider = lazy(() => import("../../components/commons/mainSlider"));
const Paragraph = lazy(() => import("../../components/commons/Paragraph")); */

import HeroViews from "../../components/commons/heroViews"
import CaymanSection from "./caymanSection"
import MainSlider from "../../components/commons/mainSlider"
import Paragraph from "../../components/commons/Paragraph"

export default () => {
  const theme = useContext(ThemeContext);

  const isMobile = useMediaQuery({
    query: "(max-device-width: 992px)"
  });

  const [data, setData] = useState();
  const history = createBrowserHistory();
  useEffect(() => {
    fetchData("https://morritts.com/wordpress/wp-json/wp/v2/pages/757");
  }, []);

  async function fetchData(url) {
    try {
      const response = await axios.get(url);
      setData(response.data.acf);
      setHeaders(response.data, history.location.pathname);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Suspense fallback={<div></div>}>
      {data ? (
        <AnimatedWrap>
          <HeroViews bg={data.hero_image} title="Cayman Islands"></HeroViews>

          {!isMobile ? (
            <Wrapper>
              <CaymanSection
                title={data.first_module_title}
                text={data.first_module_text}
                image={data.first_module_image}
                subtitle={data.first_module_subtitle}
              ></CaymanSection>
              <CaymanSection
                text={data.second_module_text}
                image={data.second_module_image}
                gridOrder={"'image text'"}
                middleSection={true}
                customTop={"-70px"}
                imageToEdge={true}
              ></CaymanSection>
              <CaymanSection
                columnGap="38px"
                title={data.caymankind_title}
                text={data.caymankind_text}
                image={data.caymankind_image}
                moreinfo={data.find_out_more_text}
                link={"https://www.visitcaymanislands.com"}
              ></CaymanSection>
              <SliderWrap>
                <MainSlider
                  images={data.gallery}
                  title={"Cayman islands "}
                  color={theme.blackColor()}
                  customMargin={"auto"}
                />
              </SliderWrap>
            </Wrapper>
          ) : (
            <MobileWrap>
              <TitleMobile>{data.first_module_title}</TitleMobile>
              <SubtitleMobile>{data.first_module_subtitle}</SubtitleMobile>
              <Paragraph text={data.first_module_text}></Paragraph>
              <RoundedMobilePic
                bgMobile={data.first_module_image}
                customHeight={"323px !important"}
              />
              <Paragraph text={data.second_module_text}></Paragraph>
              <MobileFullPicture bg={data.second_module_image} />
              <TitleMobile>{data.caymankind_title}</TitleMobile>
              <Paragraph text={data.caymankind_text}></Paragraph>
              <MobileFullPicture bg={data.caymankind_image} />
              <SubtitleMobile>
                <Paragraph text={data.find_out_more_text}></Paragraph>
              </SubtitleMobile>
              <SliderWrap>
                <MainSlider
                  images={data.gallery}
                  title={"Cayman islands "}
                  color={theme.blackColor()}
                  customMargin={"auto"}
                />
              </SliderWrap>
            </MobileWrap>
          )}
        </AnimatedWrap>
      ) : (
        <div></div>
      )}
    </Suspense>
  );
};
