import React, { Suspense, useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { createBrowserHistory } from "history";
import { Wrapper, ColumnsWrap } from "./style";
import axios from "axios";
import { AnimatedWrap } from "../../components/commons/AnimatedComponents";
import {setHeaders} from "../../utils/setHeaders"

/* const HeroViews = lazy(() => import("../../components/commons/heroViews"));
const TwoColumnsCard = lazy(() =>
  import("../../components/commons/twoColumnCard")
);
const Video = lazy(() => import("../../components/commons/video")); */

import HeroViews from "../../components/commons/heroViews"
import TwoColumnsCard from "../../components/commons/twoColumnCard"
import Video from "../../components/commons/video"

export default () => {
  const isMobile = useMediaQuery({
    query: "(max-device-width: 992px)"
  });

  const [apiData, setApiData] = useState(null);
  const history = createBrowserHistory();

  useEffect(() => {
    fetchData("https://morritts.com/wordpress/wp-json/wp/v2/pages/506");
  }, []);

  async function fetchData(url) {
    try {
      const response = await axios.get(url);
      setApiData(response.data.acf);
      setHeaders(response.data, history.location.pathname);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Suspense fallback={<div></div>}>
      {apiData ? (
        <AnimatedWrap>
          <Wrapper>
            <HeroViews bg={apiData.hero_image} title={"Activities"} />
            {!isMobile && (
              <AnimatedWrap>
                <Video ActivitiesVideo={apiData.video} />
              </AnimatedWrap>
            )}

            <ColumnsWrap>
              <TwoColumnsCard
                data={[
                  {
                    upperTitle: "",
                    title: "Sea",
                    image: apiData.sea_image,
                    text: apiData.sea_text,

                    route: "/activities/sea"
                  },
                  {
                    upperTitle: "",
                    title: "Land",
                    image: apiData.land_image,
                    text: apiData.land_text,
                    route: "/activities/land"
                  }
                ]}
                noCaret={true}
              />
              <TwoColumnsCard
                data={[
                  {
                    upperTitle: "",
                    title: "Spa",
                    image: apiData.spa_image,
                    text: apiData.spa_text,
                    route: "/activities/spa"
                  },
                  {
                    upperTitle: "",
                    title: "Shopping",
                    image: apiData.shopping_image,
                    text: apiData.shopping_text,
                    route: "/activities/shopping"
                  }
                ]}
                noCaret={true}
              />
              <TwoColumnsCard
                data={[
                  {
                    upperTitle: "",
                    title: "Daily Activities",
                    image: apiData.daily_activities_image,
                    text: apiData.daily_activities_text,
                    route: "/activities/daily-activities"
                  }
                ]}
                hideCard={true}
                noCaret={true}
              />
            </ColumnsWrap>
          </Wrapper>
        </AnimatedWrap>
      ) : (
        <div></div>
      )}
    </Suspense>
  );
};
