import React, {
  Suspense,
  lazy,
  useContext,
  Fragment,
  useEffect,
  useState
} from "react";
import { useParams } from "react-router-dom";
import { ThemeContext } from "styled-components";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import { AnimatedWrap } from "../../components/commons/AnimatedComponents";

// STYLES
import {
  Wrapper,
  RightSection,
  LeftSection,
  Autor,
  Title,
  Text,
  MobileWrapper,
  HeadlineMobile
} from "./style";

import ShareModule from "../../components/ShareModule/ShareModule";

// COMPONENTS
/* const HeroViews = lazy(() => import("../../components/commons/heroViews"));
const BlogSlider = lazy(() => import("../../components/blogSlider")); */

import HeroViews from "../../components/commons/heroViews"
import BlogSlider from "../../components/blogSlider"

export default () => {
  const theme = useContext(ThemeContext);

  const isMobile = useMediaQuery({
    query: "(max-device-width: 767px)"
  });

  const [posts, setPosts] = useState(null);
  let { slug } = useParams();
  

  let currentLocation = window.location.href;

  useEffect(() => {
    getPost(
      "https://morritts.com/wordpress/wp-json/wp/v2/newsupdatesposts?slug=" +
        slug
    );
  }, []);

  async function getPost(url) {
    try {
      const response = await axios.get(url);
      setPosts(response.data);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Suspense fallback={<div></div>}>
      {posts ? (
        <AnimatedWrap>
          <Fragment>
            <HeroViews
              bg={posts[0].acf.blog_post_image}
              title={posts[0].title.rendered}
              subtitle={posts[0].acf.blog_post_date}
              isPost={true}
              blogBread={posts[0].title.rendered}
            />

            {!isMobile ? (
              <Wrapper>
                <LeftSection>
                  <Autor colorfont={theme.blackColor}>
                    Written By {posts[0].acf.blog_post_written_by}
                  </Autor>
                  <ShareModule
                    shareData={{
                      title: posts[0].title.rendered,
                      currentLocation: currentLocation
                    }}
                  />
                </LeftSection>
                <RightSection colorfont={theme.blackColor}>
                  <Title>{posts[0].title.rendered}</Title>
                  <Text>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: posts[0].acf.blog_post_description
                      }}
                    ></div>
                  </Text>
                </RightSection>
              </Wrapper>
            ) : (
              <MobileWrapper>
                <HeadlineMobile>
                  <Title>{posts[0].title.rendered}</Title>
                  <ShareModule
                    shareData={{
                      title: posts[0].title.rendered,
                      currentLocation: currentLocation
                    }}
                  />
                </HeadlineMobile>

                {/*     <Text>
                {post.description.split("<br/>").map((item, index) => {
                  return (
                    <Fragment key={index}>
                      {index === 1 && <MobileImg bg={MobileArticlePicture} />}
                      <p>{item}</p>
                    </Fragment>
                  );
                })}
              </Text> */}
                <Text>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: posts[0].acf.blog_post_description
                    }}
                  ></div>
                </Text>
              </MobileWrapper>
            )}

            <BlogSlider
              dataNews={posts[0].acf.recommended_reading_posts}
              sectionName={"news"}
            />
          </Fragment>
        </AnimatedWrap>
      ) : (
        <div></div>
      )}
    </Suspense>
  );
};
