import React, { Suspense, useEffect, useState } from "react";
import {
  Wrapper,
  Icon,
  IconTitle,
  Disclaimer,
  BorderImage,
  SubtitleMain,
  FeaturesWrap,
  FeaturesList,
  Reservations,
  Box,
  FeaturesContainer,
  FinePrint,
  FinePrintBox
} from "./style";
import axios from "axios";

import Tick from "../../../assets/tick.svg";
import { AnimatedWrap } from "../../../components/commons/AnimatedComponents";

export default props => {
  const [features, setFeatures] = useState([]);

  async function fetchFeatures(url) {
    try {
      const response = await axios.get(url);
      setFeatures(features => [...features, response.data]);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (props.data && features < props.data.acf.features.length) {
      props.data.acf.features.forEach(item => {
        fetchFeatures(
          "https://morritts.com/wordpress/wp-json/wp/v2/features/" + item.ID
        );
      });
    }
  }, [features, props.data]);

  return (
    <Suspense fallback={null}>
      {props.data && features ? (
        <Wrapper>
          <FeaturesWrap>
            <FeaturesContainer>
              <div>
                <SubtitleMain>
                  With our staycation package at Morritt’s you'll enjoy:
                </SubtitleMain>
                <FeaturesList>
                  {features.map((item, i) => {
                    return (
                      <AnimatedWrap key={i}>
                        <li>
                          <Icon
                            src={item.acf.icon_feature}
                            alt={item.title.rendered
                              .replace("&#038;", "&")
                              .replace("&#8217;", "'")}
                          />
                          <IconTitle>
                            {item.title.rendered
                              .replace("&#038;", "&")
                              .replace("&#8217;", "'")}
                          </IconTitle>
                        </li>
                      </AnimatedWrap>
                    );
                  })}
                </FeaturesList>
              </div>

              {/*  <Disclaimer>
                <span>
                  <em>*</em> {props.data.acf.staycation_package_disclaimer}
                </span>
              </Disclaimer> */}
            </FeaturesContainer>

            <Reservations>
              <BorderImage
                bg={props.data.acf.staycation_bottom_image}
                bgMobile={props.data.acf.staycation_bottom_image}
              />
              <Box>
                <h4>{props.data.acf.staycation_rates_title} </h4>
                <h5>
                  <a href={`tel:${props.data.acf.staycation_rates_tel}`}>
                    Call
                    {" " + props.data.acf.staycation_rates_tel + " "}
                    for reservations.
                  </a>
                </h5>
                <p> {props.data.acf.staycation_rates_offer} </p>
              </Box>
            </Reservations>
          </FeaturesWrap>
        </Wrapper>
      ) : (
        <div></div>
      )}
    </Suspense>
  );
};
