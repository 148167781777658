import React, { Suspense, useEffect, useState } from "react";
import { Wrapper, FinePrint, FinePrintBox, Grid, PolicyWrap } from "./style";
import axios from "axios";

export default props => {
  const [features, setFeatures] = useState([]);
  const [policy, setPolicy] = useState(null);
  const [reversePolicy, setReversePolicy] = useState([]);

  async function fetchFeatures(url) {
    try {
      const response = await axios.get(url);
      setFeatures(features => [...features, response.data]);
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchPolicy(url) {
    try {
      const response = await axios.get(url);
      setPolicy(response.data);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (props.data && features < props.data.acf.features.length) {
      props.data.acf.features.forEach(item => {
        fetchFeatures(
          "https://morritts.com/wordpress/wp-json/wp/v2/features/" + item.ID
        );
      });
    }
  }, [features, props.data]);

  useEffect(() => {
    fetchPolicy(
      "https://morritts.com/wordpress/wp-json/wp/v2/staycation_policy/"
    );
  }, []);

  if (policy && reversePolicy.length <= 0) {
    setReversePolicy(policy.reverse());
  }

  return (
    <Suspense fallback={null}>
      {policy && (
        <Wrapper>
          <FinePrint>
            <FinePrintBox>
              <Grid>
                {reversePolicy.map(singlePolicy => {
                  return (
                    <PolicyWrap key={singlePolicy.id}>
                      <h5>{singlePolicy.title.rendered}</h5>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: singlePolicy.acf.staycation_policy_text
                        }}
                      ></div>
                    </PolicyWrap>
                  );
                })}
              </Grid>
            </FinePrintBox>
          </FinePrint>
        </Wrapper>
      )}
    </Suspense>
  );
};
