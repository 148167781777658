import React, {
  Suspense,
  useContext,
  useEffect,
  useState,
  Fragment
} from "react";
import {
  Wrapper,
  FirstGrid,
  Title,
  Text,
  MainSubtitle,
  SecondGrid,
  SecondBottomGrid,
  BookingCTA,
  BottomText,
  MobileWrap,
  SliderMobileWrap,
  HeroWrap,
  PictureXs,
  PictureWrap,
  VideoWrap
} from "./style";
import { ThemeContext } from "styled-components";
import { useMediaQuery } from "react-responsive";

import axios from "axios";
import { AnimatedWrap } from "../../../components/commons/AnimatedComponents";
import { convertPhone } from "../../../utils/constants";
import {setHeaders} from "../../../utils/setHeaders"
import { createBrowserHistory } from "history";

/* const MainSlider = lazy(() => import("../../../components/commons/mainSlider"));
const Video = lazy(() => import("../../../components/commons/video"));
const Paragraph = lazy(() => import("../../../components/commons/Paragraph")); */

import MainSlider from "../../../components/commons/mainSlider"
import Video from "../../../components/commons/video"
import Paragraph from "../../../components/commons/Paragraph"

export default () => {
  const theme = useContext(ThemeContext);

  const isMobile = useMediaQuery({
    query: "(max-device-width: 992px)"
  });

  const [apiData, setApiData] = useState(null);
  const [seaPhone, setSeaPhone] = useState("");
  const history = createBrowserHistory();

  useEffect(() => {
    fetchData("https://morritts.com/wordpress/wp-json/wp/v2/pages/533");
  }, []);

  useEffect(() => {
    if (apiData && seaPhone === "") {
      setSeaPhone(convertPhone(apiData.second_module_tel));
    }
  }, [seaPhone, apiData]);

  async function fetchData(url) {
    try {
      const response = await axios.get(url);
      setApiData(response.data.acf);
      setHeaders(response.data, history.location.pathname);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Suspense fallback={null}>
      {apiData ? (
        <Fragment>
          {!isMobile ? (
            <AnimatedWrap>
              <Wrapper>
                <HeroWrap>
                  <Title>{apiData.main_title}</Title>
                  <MainSubtitle
                    mobileLineHeight={"24px"}
                    mobileSize={"16px"}
                    title={apiData.main_subtitle}
                  ></MainSubtitle>
                  <VideoWrap>
                    <Video ActivitiesVideo={apiData.video} normalVideo={true} />
                  </VideoWrap>
                </HeroWrap>

                <FirstGrid>
                  <div>
                    <MainSlider
                      images={apiData.first_module_gallery}
                      title={apiData.main_title + " "}
                      color={theme.blackColor()}
                      customMargin={"auto"}
                    />
                  </div>

                  <div>
                    <Paragraph text={apiData.first_module_text}></Paragraph>
                  </div>
                </FirstGrid>
                <SecondGrid>
                  <div>
                    <Title>{apiData.second_module_title}</Title>
                    <MainSubtitle
                      mobileLineHeight={"24px"}
                      mobileSize={"16px"}
                      title={apiData.second_module_subtitle}
                    ></MainSubtitle>
                    <Paragraph text={apiData.second_module_text_1}></Paragraph>
                  </div>
                  <div>
                    <MainSlider
                      images={apiData.second_module_gallery}
                      title={apiData.second_module_title + " "}
                      color={theme.blackColor()}
                      customMargin={"auto"}
                    />
                  </div>
                </SecondGrid>
                <SecondBottomGrid>
                  <BookingCTA>
                    <span>
                      For bookings call{" "}
                      <a
                        href={`tel:${
                          apiData.second_module_tel.substring(0, 1) === "+"
                            ? "+"
                            : null
                        }${seaPhone}`}
                      >
                        <b>{apiData.second_module_tel}</b>
                      </a>
                    </span>
                    <p>
                      {apiData.second_module_days} {apiData.second_module_time}
                    </p>
                  </BookingCTA>
                  <Paragraph text={apiData.second_module_text_2}></Paragraph>
                </SecondBottomGrid>
                <FirstGrid>
                  <div>
                    <MainSlider
                      images={apiData.third_module_gallery}
                      title={apiData.third_module_title + ""}
                      color={theme.blackColor()}
                      customMargin={"auto"}
                    />
                  </div>

                  <BottomText>
                    <Title>{apiData.third_module_title} </Title>
                    <MainSubtitle
                      mobileLineHeight={"24px"}
                      mobileSize={"16px"}
                      title={apiData.third_module_subtitle}
                    ></MainSubtitle>
                    <Paragraph text={apiData.third_module_text}></Paragraph>
                  </BottomText>
                </FirstGrid>
              </Wrapper>
            </AnimatedWrap>
          ) : (
            <MobileWrap>
              <HeroWrap>
                <Title>{apiData.main_title}</Title>
                <MainSubtitle
                  mobileLineHeight={"24px"}
                  mobileSize={"16px"}
                  title={apiData.main_subtitle}
                ></MainSubtitle>
                <PictureWrap>
                  <PictureXs
                    customHeight={"200px !important"}
                    bgMobile={apiData.mobile_image}
                  />
                </PictureWrap>
              </HeroWrap>

              <Paragraph text={apiData.first_module_text}></Paragraph>
              <SliderMobileWrap>
                <MainSlider
                  images={apiData.first_module_gallery}
                  title={apiData.main_title + " "}
                  color={theme.blackColor()}
                  customMargin={"auto"}
                />
              </SliderMobileWrap>

              <Title>{apiData.second_module_title}</Title>

              <Paragraph text={apiData.second_module_text_1}></Paragraph>
              <SliderMobileWrap>
                <MainSlider
                  images={apiData.second_module_gallery}
                  title={apiData.second_module_title + " "}
                  color={theme.blackColor()}
                  customMargin={"auto"}
                />
              </SliderMobileWrap>
              <Paragraph text={apiData.second_module_text_2}></Paragraph>
              <BookingCTA>
                <span>
                  For bookings call
                  <a
                    href={`tel:${
                      apiData.second_module_tel.substring(0, 1) === "+"
                        ? "+"
                        : null
                    }${seaPhone}`}
                  >
                    <b>{apiData.second_module_tel}</b>
                  </a>
                </span>
                <p>
                  {apiData.second_module_days} {apiData.second_module_time}
                </p>
              </BookingCTA>

              <Title>{apiData.third_module_title} </Title>
              <Paragraph text={apiData.third_module_text}></Paragraph>
              <SliderMobileWrap>
                <MainSlider
                  images={apiData.third_module_gallery}
                  title={apiData.third_module_title + " "}
                  color={theme.blackColor()}
                  customMargin={"auto"}
                />
              </SliderMobileWrap>
            </MobileWrap>
          )}
        </Fragment>
      ) : (
        <div></div>
      )}
    </Suspense>
  );
};
