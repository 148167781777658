import React, { Suspense, lazy } from "react";
import { RouteLayout } from "../components";
import submenuTest from "../assets/submenu-test.jpg";
import dineDetail from "./dineDetail";
// const Home = lazy(() => import("./home"));
// const Stay = lazy(() => import("./stay"));
// const Hotel = lazy(() => import("./hotel"));
// const Blog = lazy(() => import("./blog"));
// const Post = lazy(() => import("./post"));
// const PostNews = lazy(() => import("./postNews"));
// const Dine = lazy(() => import("./dine"));
// const Activities = lazy(() => import("./activities"));
// const DineDetail = lazy(() => import("./dineDetail"));
// const ActivitiesDetail = lazy(() => import("./activities/activitiesDetail"));
// const Contact = lazy(() => import("./contact"));
// const CaymanIslands = lazy(() => import("./caymanIslands"));
// const VacationClub = lazy(() => import("./vacationClub"));
// const Staycation = lazy(() => import("./staycation"));
// const NewsAndUpdates = lazy(() => import("./newsAndUpdates"));

import Home from "./home";
import Stay from "./stay";
import Hotel from "./hotel";
import Blog from "./blog";
import Post from "./post";
import PostNews from "./postNews";
import Dine from "./dine";
import Activities from "./activities";
import DineDetail from "./dineDetail";
import ActivitiesDetail from "./activities/activitiesDetail";
import Contact from "./contact";
import CaymanIslands from "./caymanIslands";
import VacationClub from "./vacationClub";
import Staycation from "./staycation";
import NewsAndUpdates from "./newsAndUpdates";
import PrivacyPolicy from "./privacyPolicy"

const route = C => (
  <Suspense fallback={null}>
    <RouteLayout>
      <C />
    </RouteLayout>
  </Suspense>
);
export default [
  {
    id: "staycation",
    parentId: null,
    exact: true,
    path: "/staycation",
    label: "Staycation",
    component: () => route(Staycation)
  },
  {
    id: "stay",
    parentId: null,
    isSection: true,
    exact: true,
    label: "Stay",
    path: "/stay",
    component: () => route(Stay)
  },
  {
    id: "the-londoner",
    parentId: "stay",
    sectionId: 0,
    exact: true,
    path: "/stay/the-londoner",
    label: "The Londoner",
    img: submenuTest,
    component: () => route(Hotel)
  },
  {
    id: "grand-resort",
    parentId: "stay",
    sectionId: 0,
    exact: true,
    path: "/stay/grand-resort",
    label: "Grand Resort",
    img: submenuTest,
    component: () => route(Hotel)
  },
  {
    id: "tortuga-club",
    parentId: "stay",
    sectionId: 1,
    exact: true,
    path: "/stay/tortuga-club",
    label: "Tortuga Club",
    img: submenuTest,
    component: () => route(Hotel)
  },
  {
    id: "virtual-tours",
    parentId: "stay",
    sectionId: 1,
    exact: true,
    path: "/stay/virtual-tours",
    label: "Virtual Tours",
    img: submenuTest,
    component: () => route(Hotel)
  },
  {
    id: "home",
    parentId: null,
    isSection: false,
    sectionId: 0,
    exact: true,
    label: "Home",
    path: "/",
    component: () => route(Home)
  },
  {
    id: "dine",
    parentId: null,
    isSection: true,
    exact: true,
    label: "Dine",
    path: "/dine",
    component: () => route(Dine)
  },
  {
    id: "David’s ",
    sectionId: 0,
    parentId: "dine",
    exact: false,
    path: "/dine/david-deep-blue",
    label: "David’s ",
    img: submenuTest,
    component: () => route(dineDetail)
  },
  {
    id: "Mimi’s Dock Bar",
    sectionId: 0,
    parentId: "dine",
    exact: false,
    path: "/dine/mimi-dock",
    label: "Mimi’s Dock Bar",
    img: submenuTest,
    component: () => route(dineDetail)
  },
  {
    id: "The Cavern",
    sectionId: 0,
    parentId: "dine",
    exact: false,
    path: "/dine/the-cavern",
    label: "The Cavern",
    img: submenuTest,
    component: () => route(dineDetail)
  },
  {
    id: "Pool Bars",
    sectionId: 0,
    parentId: "dine",
    exact: false,
    path: "/dine/pool-bars",
    label: "Pool Bars",
    img: submenuTest,
    component: () => route(dineDetail)
  },

  {
    id: "activities",
    parentId: null,
    isSection: true,
    exact: true,
    label: "Activities",
    path: "/activities",
    component: () => route(Activities)
  },
  {
    id: "sea",
    sectionId: 0,
    parentId: "activities",
    exact: true,
    path: "/activities/sea",
    label: "Sea",
    img: submenuTest,
    component: () => route(ActivitiesDetail)
  },
  {
    id: "land",
    sectionId: 0,
    parentId: "activities",
    exact: true,
    path: "/activities/land",
    label: "Land",
    img: submenuTest,
    component: () => route(ActivitiesDetail)
  },
  {
    id: "spa",
    sectionId: 0,
    parentId: "activities",
    exact: true,
    path: "/activities/spa",
    label: "Spa",
    img: submenuTest,
    component: () => route(ActivitiesDetail)
  },
  {
    id: "shopping",
    sectionId: 0,
    parentId: "activities",
    exact: true,
    path: "/activities/shopping",
    label: "Shopping",
    img: submenuTest,
    component: () => route(ActivitiesDetail)
  },
  {
    id: "daily-activities",
    sectionId: 0,
    parentId: "activities",
    exact: true,
    path: "/activities/daily-activities",
    label: "Daily Activities",
    img: submenuTest,
    component: () => route(ActivitiesDetail)
  },
  {
    id: 2,
    isSection: true,
    label: "Activities detail",
    exact: true,
    path: "/activities/spa",
    component: () => route(ActivitiesDetail)
  },
  {
    id: "cayman-islands",
    parentId: null,
    exact: true,
    path: "/cayman-islands",
    label: "Cayman Islands",
    component: () => route(CaymanIslands)
  },
  {
    id: "vacationclub",
    parentId: null,
    isSection: true,
    label: "Vacation Club",
    path: "/vacation-club/ownership-&-benefits",
    component: () => route(VacationClub)
  },
  {
    id: "ownershipAndBenefits",
    sectionId: 3,
    parentId: "vacationclub",
    exact: true,
    path: "/vacation-club/ownership-&-benefits",
    label: "Ownership & Benefits",
    img: "https//placehold.it",
    component: () => route(VacationClub)
  },
  {
    id: "OwnerLogin",
    sectionId: 3,
    parentId: "vacationclub",
    exact: true,
    path: "/",
    label: "Owner Login",
    img: "https//placehold.it",
    component: () => route(Home)
  },
  {
    id: "NewsAndUpdate",
    sectionId: 3,
    parentId: "vacationclub",
    exact: true,
    path: "/vacation-club/news-&-updates",
    label: "News & Updates",
    img: "https//placehold.it",
    component: () => route(NewsAndUpdates)
  },
  {
    exact: true,
    id: "blog",
    parentId: null,
    path: "/blog",
    label: "Blog",
    component: () => route(Blog)
  },
  {
    sectionId: 0,
    exact: true,
    id: "post",
    parentId: "blog",
    path: "/blog/:slug",
    label: "Post",
    component: () => route(Post)
  },
  {
    sectionId: 0,
    exact: true,
    id: "post",
    parentId: "blog",
    path: "/preview/blog_posts/:slug",
    label: "Post",
    component: () => route(Post)
  },
  {
    sectionId: 0,
    exact: true,
    id: "post",
    parentId: "NewsAndUpdate",
    path: "/vacation-club/news-&-updates/:slug",
    label: "Post",
    component: () => route(PostNews)
  },
  {
    sectionId: 0,
    exact: true,
    id: "post",
    parentId: "NewsAndUpdate",
    path: "/preview/newsupdatesposts/:slug",
    label: "Post",
    component: () => route(PostNews)
  },
  {
    exact: true,
    id: "contact",
    parentId: null,
    path: "/contact",
    label: "Contact",
    component: () => route(Contact)
  },
  {
    exact: true,
    id: "privacy-policy",
    parentId: null,
    path: "/privacy-policy",
    label: "Privacy Policy",
    noMainNav: true,
    component: () => route(PrivacyPolicy)
  }
];
