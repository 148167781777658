import React, { useState } from "react";

// STYLES
import {
  Wrapper,
  Input,
  White,
  ShowPassword,
  hidePassword,
  ShowError
} from "./style";

export default props => {
  // HOOKS
  const [isPassword, setIsPassword] = useState(true);

  // HANDLERS
  const changeType = () => {
    setIsPassword(!isPassword);
  };

  return (
    <Wrapper>
      <Input
        errorMail={props.errorMail}
        inputItem={props.inputItem}
        name={props.name}
        as={props.theme === "white" ? White : ""}
        placeholder={props.label}
        type={
          props.inputtype === "password" && isPassword ? "password" : "text"
        }
        value={props.value}
        onChange={e => {
          props.handleInputChange({
            name: props.name,
            value: e.target.value
          });
        }}
      ></Input>
      {props.errorMail === false && props.inputItem === "email" && (
        <ShowError  />
      )}
      {props.inputtype === "password" && (
        <ShowPassword
          as={isPassword ? "" : hidePassword}
          onClick={() => changeType()}
        />
      )}
    </Wrapper>
  );
};
