import React, { useEffect, useState, Fragment } from "react";
import axios from "axios";
import { Wrapper, ColumnsWrap } from "./style";
import { AnimatedWrap } from "../../components/commons/AnimatedComponents";
import HeroViews from "../../components/commons/heroViews";
import TwoColumnsCard from "../../components/commons/twoColumnCard";
import {setHeaders} from "../../utils/setHeaders"

/* const HeroViews = lazy(() => import("../../components/commons/heroViews"));
const TwoColumnsCard = lazy(() =>
  import("../../components/commons/twoColumnCard")
); */

export default () => {
  const [dineData1, setDineData] = useState([]);
  const [dineData2, setDineData2] = useState([]);
  const [heroImg, setHeroImg] = useState();

  useEffect(() => {
    fetchData("https://morritts.com/wordpress/wp-json/wp/v2/pages/379");
  }, []);

  async function fetchData(url) {
    try {
      const response = await axios.get(url);
      setHeroImg(response.data.acf.dine_hero_image);
      setHeaders(response.data);
      setDineData([
        {
          upperTitle: "",
          title: "David's",
          image: response.data.acf.davids_deep_blue_image,
          text: response.data.acf.davids_deep_blue_text,

          route: "/dine/david-deep-blue"
        },
        {
          upperTitle: "",
          title: "Mimi’s Dock Bar",
          image: response.data.acf.mimis_dock_bar_image,
          text: response.data.acf.mimis_dock_bar_text,
          route: "/dine/mimi-dock"
        }
      ]);
      setDineData2([
        {
          upperTitle: "",
          title: "The Cavern",
          image: response.data.acf.ivans_sports_bar_image,
          text: response.data.acf.ivans_sports_bar_text,
          route: "/dine/the-cavern"
        },
        {
          upperTitle: "",
          title: "Pool Bars ",
          image: response.data.acf.pools_bar_image,
          text: response.data.acf.pools_bar_text,
          route: "/dine/pool-bars"
        }
      ]);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Fragment>
 {/*      {dineData1 && dineData2 && heroImg && ( */}
        <AnimatedWrap>
          <Wrapper>
            <HeroViews bg={heroImg} title={"Dine"} />
            <ColumnsWrap>
              <TwoColumnsCard data={dineData1} />
              <TwoColumnsCard data={dineData2} />
            </ColumnsWrap>
          </Wrapper>
        </AnimatedWrap>
   {/*    )} */}
    </Fragment>
  );
};
