import React, { useEffect, useState } from "react";
import { toggleMobileMenu } from "../../../redux/actions";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import routes from "../../../views/routes";

// STYLES
import { Wrapper, Breadcrumb, Current } from "./style";

const BreadCrumbs = ({ menuOpen, blogBread }) => {
  const location = useLocation();
  const [breadcrumb, setBreadcrumb] = useState([]);

  useEffect(() => {
    let myRoute = {};
    if (location.pathname[location.pathname.length - 1] === "/")
      myRoute = routes.find(route => route.path + "/" === location.pathname);
    else myRoute = routes.find(route => route.path === location.pathname);

    if (!myRoute) {
      let isBlog = location.pathname.split("/");
      if (isBlog.length >= 2 && isBlog[1] === "blog" && isBlog[2] !== "") {
        let route = [
          {
            path: "/blog",
            label: "Blog"
          },
          {
            label: blogBread
          }
        ];
        setBreadcrumb(route);
      }
   
      if (isBlog[2] === "news-&-updates") {
        let route = [
          {
            path: "/vacation-club/news-&-updates",
            label: "News & Updates"
          },
          {
            label: blogBread
          }
        ];
        setBreadcrumb(route);
      }
    }

    const fillBreadcrum = (myRoute, cBreadcrum) => {
      cBreadcrum.push(myRoute);
      if (myRoute.parentId) {
        const parentRoute = routes.find(route => route.id === myRoute.parentId);
        if (parentRoute) {
          return fillBreadcrum(parentRoute, cBreadcrum);
        }
        return cBreadcrum.reverse();
      }
      return cBreadcrum.reverse();
    };

    if (myRoute) {
      if (myRoute.parentId) {
        const result = fillBreadcrum(myRoute, []);
        setBreadcrumb(result);
      } else {
        setBreadcrumb([myRoute]);
      }
    }
    return;
  }, [location, menuOpen]);
  return (
    <Wrapper menuOpen={menuOpen}>
      {breadcrumb.length && (
        <div>
          <Breadcrumb to="/">Home</Breadcrumb>
          {breadcrumb.map((r, index) => {
            return index !== breadcrumb.length - 1 ? (
              <Breadcrumb
                as={index === breadcrumb.length - 1 ? Current : ""}
                to={r.path}
                key={index}
              >
                {r.label}
              </Breadcrumb>
            ) : (
              <Current key={index}>{r.label}</Current>
            );
          })}
        </div>
      )}
    </Wrapper>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    menuToggle: () => dispatch(toggleMobileMenu())
  };
};

const mapStateToProps = state => {
  return {
    menuOpen: state.toggleMobileMenu.menuOpened
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BreadCrumbs);
