import React, { useState, useEffect, Fragment } from "react";
import TextareaAutosize from "react-autosize-textarea";
import axios from "axios";

// STYLES
import {
  Wrapper,
  MorritForm,
  Button,
  customStyles,
  SendButton,
  TextAreaWrap,
  SelectWrap,
  EmailAlert,
  ErrorMessage
} from "./style";

// COMPONENTS
import Input from "../../../components/commons/input";
import Select from "react-select";

export default props => {
  // HOOKS
  const [form, setForm] = useState({
    select: "",
    fullname: "",
    email: "",
    subject: "",
    message: ""
  });

  // HANDLERS
  const handleOnChange = e => {
    const newForm = {
      ...form
    };
    newForm[e.name] = e.value;
    setForm(newForm);
  };

  const upperCase = lower => {
    const upper = lower.replace(/^\w/, c => c.toUpperCase());
    return upper;
  };

  const [departementValue, setDepartementValue] = useState(null);
  const [validateEmail, setValidateEmail] = useState("empty");
  const [valueEmail, setValueEmail] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [departementLastArray, setDeparmentLastArray] = useState(null);

  const handleSelect = e => {
    setDepartementValue(e.value);
  };

  const handleValidMail = email => {
    let re = /\S+@\S+\.\S+/;

    setValueEmail(email.value);

    if (re.test(email.value)) {
      setValidateEmail(true);
    } else {
      setValidateEmail(false);
    }
  };

  useEffect(() => {
    setValidateEmail("empty");

    let finalDepartments = [];
    props.selectOptions.forEach((option, i) => {
      finalDepartments.push({
        label: option,
        value: option
      });
    });

    setDeparmentLastArray(finalDepartments);
  }, []);

  const submitForm = async () => {
    try {
      var bodyFormData = new FormData();
      bodyFormData.set("department", departementValue);
      bodyFormData.set("fullname", form.fullname);
      bodyFormData.set("email", valueEmail);
      bodyFormData.set("subject", form.subject);
      bodyFormData.set("message", form.message);
      await axios({
        method: "post",
        url:
          "https://morritts.com/wordpress/wp-json/contact-form-7/v1/contact-forms/96/feedback",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" }
      });
      setShowSuccess(true);
    } catch (error) {}
  };

  return (
    <Wrapper>
      <MorritForm autoComplete="off">
        {Object.keys(form).map((item, index) => {
          if (item === "message") {
            return (
              <TextAreaWrap key={index}>
                <TextareaAutosize
                  placeholder="Message"
                  onChange={e => handleOnChange(e.target)}
                  name={item}
                  value={form[{ item }]}
                  required
                />
              </TextAreaWrap>
            );
          }
          if (item !== "select")
            return (
              <Fragment key={index}>
                <Input
                  name={item}
                  value={form[{ item }]}
                  label={upperCase(item)}
                  handleInputChange={
                    item === "email" ? e => handleValidMail(e) : handleOnChange
                  }
                  theme="white"
                  errorMail={validateEmail}
                  inputItem={item}
                ></Input>
                {!validateEmail && item === "email" && (
                  <EmailAlert>
                    This doesn’t look like a valid email adress.
                  </EmailAlert>
                )}
              </Fragment>
            );
          else {
            return (
              <SelectWrap key={index}>
                {departementLastArray && (
                  <Select
                    styles={customStyles}
                    options={departementLastArray}
                    /*   defaultValue={departement[0]} */
                    onChange={handleSelect}
                    placeholder={"Select department..."}
                    isSearchableboolean={false}
                    isSearchable={false}
                    inputProps={{ readOnly: true }}
                    blurInputOnSelect={true}
                    readonly
                  />
                )}
              </SelectWrap>
            );
          }
        })}
        <Button
          onClick={() => {
            if (
              valueEmail !== "" &&
              form.fullname !== "" &&
              departementValue !== null &&
              form.subject !== "" &&
              form.message !== ""
            ) {
              submitForm();
              setShowErrorMessage(false);
            } else {
              setShowErrorMessage(true);
            }
          }}
        >
          {showErrorMessage && (
            <ErrorMessage>All fields are required</ErrorMessage>
          )}
          {showSuccess && <ErrorMessage>success!</ErrorMessage>}
          <SendButton onClick={e => e.preventDefault()}>Send</SendButton>
        </Button>
      </MorritForm>
    </Wrapper>
  );
};
