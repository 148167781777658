import React, { useState, useEffect, Fragment } from "react";
import {
  Navs,
  Submenu,
  Section,
  StyledNavlink,
  SubmenuLink,
  ButtonToggle,
  ImageWrap,
  ImageArrow
} from "./style";
import routes from "../../../views/routes";
import { toggleMobileMenu } from "../../../redux/actions";
import { connect } from "react-redux";

import ArrowPic from "../../../assets/arrow-down-menu.svg";

export const MobileNav = ({ isDesk, menuToggle, menuOpen }) => {
  const [openedMenu, setOpenedMenu] = useState(-1);
  const [clicked, setClicked] = useState(false);

  useEffect(() => {}, [openedMenu, clicked]);

  return (
    <Navs isDesk={isDesk} menuOpen={menuOpen}>
      {routes
        .filter(r => !r.hasOwnProperty("sectionId"))
        .map((route, i) => {
          if (route.isSection) {
            let subRoutes = routes.filter(
              r => r.hasOwnProperty("sectionId") && r.parentId === route.id
            );
            if (subRoutes.length) {
              return (
                <React.Fragment key={i}>
                  <Section
                    onClick={() => {
                      setOpenedMenu(i);
                      setClicked(!clicked);
                    }}
                  >
                    <ImageWrap>
                      <ButtonToggle>{route.label}</ButtonToggle>

                      <ImageArrow
                        src={ArrowPic}
                        alt="arrow"
                        openedMenu={openedMenu}
                        index={i}
                      />
                    </ImageWrap>

                    <Submenu
                      index={i}
                      openedMenu={openedMenu}
                      elemHeight={
                        i === 1
                          ? "205px"
                          : i === 2
                          ? "205px"
                          : i === 3
                          ? "250px"
                          : "130px"
                      }
                    >
                      {i === 1 && (
                        <SubmenuLink onClick={menuToggle} to={"/stay"}>
                          <span>Overview</span>
                        </SubmenuLink>
                      )}
                       {i === 2 && (
                        <SubmenuLink onClick={menuToggle} to={"/dine"}>
                          <span>Overview</span>
                        </SubmenuLink>
                      )}
                        {i === 3 && (
                        <SubmenuLink onClick={menuToggle} to={"/activities"}>
                          <span>Overview</span>
                        </SubmenuLink>
                      )}
                      {subRoutes.map((subRoute, k) => (
                        <Fragment key={k}>
                         
                          {subRoute.label === "Owner Login" ? (
                            <SubmenuLink
                              onClick={menuToggle}
                              to={{
                                pathname:
                                  "https://amtvmiissvr.morritts.com/"
                              }}
                              target="_blank"
                            >
                              <span>{subRoute.label}</span>
                            </SubmenuLink>
                          ) : (
                            <SubmenuLink
                              onClick={menuToggle}
                              key={k}
                              exact={subRoute.exact}
                              to={subRoute.path}
                            >
                              <span>{subRoute.label}</span>
                            </SubmenuLink>
                          )}
                        </Fragment>
                      ))}
                    </Submenu>
                  </Section>
                </React.Fragment>
              );
            } else return null;
          } else {
            return (
              <StyledNavlink
                key={i}
                exact={route.exact}
                to={route.path}
                onClick={menuToggle}
              >
                {route.label}
              </StyledNavlink>
            );
          }
        })}
    </Navs>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    menuToggle: () => dispatch(toggleMobileMenu())
  };
};

const mapStateToProps = state => {
  return {
    menuOpen: state.toggleMobileMenu.menuOpened
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileNav);
