import styled from "styled-components";
import { Link } from "react-router-dom";

export const Wrapper = styled(Link)`
  min-height: 480px;
  border-radius: 16px;
  background-color: #ffffff;
  box-shadow: 0 20px 80px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: block;
  text-decoration: none;
  transition: ${props => props.theme.defaultTransition("all", 0.4)};

  position: relative;

  &:hover {
    box-shadow: 0 20px 80px 0 rgba(0, 0, 0, 0.18);
  }
`;

export const Image = styled.div`
  background: url(${props => props.image}) no-repeat center center / cover;
  height: 200px;
  width: 100%;
`;

export const Title = styled.h2`
  color: ${props => props.color};
  font-family: Poppins;
  font-size: 32px;
  font-weight: bold;
  line-height: 40px;
  padding: 10px 20px 0;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  opacity: 1 !important;
  visibility: visible !important;
`;

export const Text = styled.p`
  color: ${props => props.color};
  font-family: Roboto;
  font-size: 16px;
  line-height: 24px;
  padding: 16px 20px 24px;
  ul {
    display: none;
  }

  p {
    visibility: hidden;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    opacity: 1 !important;
    visibility: visible !important;
  }
  *:not(:nth-child(1)) {
    display: none;
  }
`;

export const Footer = styled.div`
  color: ${props => props.color};
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  font-family: Roboto;
  font-weight: bold;

  position: absolute;
  width: 100%;
  bottom: -0.5rem;
`;

export const Date = styled.p`
  color: ${props => props.theme.darkBlue};
  line-height: 24px;
  font-size: 12px;
`;
export const ViewMore = styled.div`
  color: ${props => props.theme.skyBlue};
  line-height: 24px;
  font-size: 16px;
  text-decoration: none;
`;
